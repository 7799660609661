:root {
  --color-Special: #c92143;
  --color-Primary: #3aa7d8;
  --color-positive: #068832;
  --color-accent1: #254b5c;
  --color-accent2: #033245;
  --color-neutral0: #fff;
  --color-neutral200: #f5f5f5;
  --color-neutral666: #666666;
  --color-neutral800: #434343;
  --color-neutral900: #000;

  --border-color: #c4c4c4;

  --breakpoint-tablet: 600px;
}
