#dialog {
    .MuiPaper-rounded {
      background: #ffffff;
      border-radius: 20px;
      padding: 24px 16px;
      .MuiTypography-h6 {
        font-size: 30px;
        font-weight: 700;
        line-height: 35px;
        text-align: center;
      }
      .MuiDialogContent-root {
        text-align: center;
        button {
          background: #000;
          border-radius: 20px;
          padding: 8px 24px 8px 24px;
  
          font-size: 22px;
          font-weight: 700;
          line-height: 26px;
          color: #fff;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          color: #000;
          margin-top: 17px;
          margin-bottom: 37px;
          max-width: 278px;
        }
      }
  
      .MuiDialogActions-root {
        justify-content: center;
        button {
          width: 100%;
          height: 48px;
        }
      }
    }
  }
  