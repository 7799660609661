#checkout {
  .accordion {
    .card {
      border: 0;
      .card-header {
        display: flex;
        align-items: center;
        background: var(--color-neutral200);
        padding: 16px;
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        svg {
          margin-right: 4px;
        }
        span {
          margin-left: auto;
        }
      }
      .card-body {
        padding: 0;
        background: var(--color-neutral200);
        .detail-section {
          padding: 16px;
          .heading-section {
            display: flex;
            p {
              font-size: 18px;
              font-weight: 500;
              line-height: 21px;
              margin-bottom: 16px;
              &:last-child {
                margin-left: auto;
              }
            }
          }
          .details {
            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              margin-bottom: 18px;
            }
          }
        }
        .total-section {
          div {
            display: flex;
            padding: 0 16px;
            p {
              font-size: 16px;
              font-weight: 500;
              line-height: 19px;
              margin-bottom: 18px;
              &:nth-child(2) {
                margin-left: auto;
              }
            }
            &:last-child {
              margin-top: 10px;
              border-top: 1px solid var(--border-color);
              padding-top: 16px;
            }
          }
        }
      }
    }
  }

  .pickup-details {
    padding: 16px;
    .heading-section {
      display: flex;
      p {
        margin-left: auto !important;
        font-size: 14px;
        font-weight: 700 !important;
        line-height: 16px;
        color: var(--color-Special);
      }
    }
    h2 {
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
      margin-bottom: 32px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      &:last-child {
        font-weight: 400;
        margin: 0;
      }
    }
  }
  .your-details {
    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.4;
    }
  }
  .your-details,
  .payment-information {
    padding: 16px;
    .gift-card {
      background: rgb(63, 187, 236);
      color: var(--color-neutral0);
      align-items: center;
      padding: 16px 0;
      border-top: 1px solid var(--border-color);
      border-bottom: 1px solid var(--border-color);
      // margin-bottom: 16px;
      cursor: pointer;
      p {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        margin: 0;
        width: 100%;
        text-align: center;
      }
      svg {
        margin-left: auto;
      }
    }
    .form-group {
      .form-label {
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 16px;
      }
      .form-control {
        height: 48px;
      }
    }
    .btn {
      width: 100%;
      border-radius: 0;
    }
    h2 {
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
      margin-bottom: 32px;
    }
    .required {
      text-align: right;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      margin-top: 8px;
    }
    .helping-text {
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
      margin-top: 26px;
      margin-bottom: 72px;
    }
    .order-helping-text {
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
      margin-top: 26px;
    }
  }

  .payment-information {
    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.4;
    }
    .btn {
      // position: absolute;
      width: 100%;
      left: 0;
      // height: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
    }

    .total-summary {
      display: flex;
      p:last-child {
        margin-left: auto;
      }
    }
  }
}

.radiButtonLabelColor {
  label {
    color: black !important;
  }
}

.sms-star-text {
  color: red !important;
  font-weight: 800;
  font-size: 12px;
}

.checkboxLabelColor {
  label {
    color: black !important;
  }
}

.checkboxRequired {
  label {
    color: red !important;
  }
}
.gift {
  font-size: 20px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 20px;
}
.giftCards {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
.linkText {
  font-size: 12px;
  div:hover {
    color: blue;
    text-decoration-line: underline;
  }
}
.radiButtonLabelColors {
  label {
    margin-left: 10px;
  }
  .form-check-input {
    height: 20px;
    width: 20px;
  }
}
.specialRequest {
  font-size: 18px;
  text-align: center;
  div:hover {
    color: blue;
    text-decoration-line: underline;
    cursor: pointer;
  }
}
.radiButtonLabelPayAtPickup {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 30px;
  label {
    margin-left: 15px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
  }
  .form-check-input {
    height: 20px;
    width: 20px;
  }
}
.or{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  padding-bottom: 30px;
}
