#expandedMenu {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .header {
    border: 1px solid var(--border-color);
    background: var(--color-neutral0);
    img {
      position: absolute;
      right: 16px;
      top: 13px;
    }
    a {
      position: absolute;
      left: 16px;
      top: 13px;
      svg {
        font-size: 22px;
        line-height: 37px;
        color: var(--color-neutral900);
      }
    }
    .heading {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: var(--color-neutral800);
      text-transform: uppercase;
      padding: 15px 0;
      text-align: center;
    }    
  }
  .subHeading {
    p {
      background: var(--color-accent1);
      height: 64px;
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      display: flex;
      align-items: center;
      color: var(--color-neutral0);
      border-bottom: 1px solid var(--color-accent2);
      margin-bottom: 0;
      img {
        margin: 0 16px;
      }
    }
  }
  .menuList {
    padding: 16px;
    h2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      span {
        float: right;
      }
    }
    p {
      font-size: 16px;
      line-height: 19px;
    }
    .btn-section {
      text-align: right;
      button {
        padding: 8px 16px;
        background: var(--color-Special);
        border-radius: 20px;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        &:first-child {
          margin-right: 16px;
        }
        &:last-child {
          background: var(--color-neutral0);
          color: var(--color-Special);
        }
      }
    }
  }
  .footer {
    background: var(--color-accent2);
    padding: 16px;
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    bottom: -72px;
    transition-property: bottom;
    transition-duration: 0.5s;
    &.show {
      bottom: 0;
    }
    button {
      padding: 6px 16px 6px 24px;
      background: var(--color-neutral0);
      border-radius: 20px;
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      color: var(--color-Special);
      svg {
        margin-left: 24px;
        font-size: 18px;
      }
    }
    .amount {
      float: right;
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      color: var(--color-neutral0);
      padding-right: 31px;
    }
  }
}

.cartIconContainer{
  cursor: pointer;
}