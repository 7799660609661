#restaurants {
  padding: 16px;
  .section {
    background: var(--color-neutral200);
    border-radius: 24px;
    border: 1px solid var(--color-neutral0);
    padding: 16px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 36px;
    }
    .heading {
      font-weight: bold;
      font-size: 30px;
      line-height: 35px;
      color: var(--color-neutral900);
      margin-bottom: 8px;
    }
    .pills {
      p {
        font-size: 10px;
        line-height: 11px;
        text-transform: uppercase;
        color: var(--color-neutral900);
        font-weight: 800;
        background: var(--color-neutral0);
        border: 1px solid var(--color-neutral0);
        border-radius: 2px;
        padding: 5px;
        margin: 0;
        margin-right: 8px;
        svg {
          color: var(--color-Special);
          margin-right: 4px;
        }
      }
    }
    .desc {
      margin-top: 8px;
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 19px;
      color: var(--color-neutral900);
    }
    .list-item {
      p {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 8px;
        svg {
          font-size: 12px;
          line-height: 14px;
          color: var(--color-Special);
          margin-right: 10px;
          width: 11px;
          &.chev-right {
            margin-left: 8px;
            color: var(--color-neutral900);
          }
        }
      }
      #timings {
        margin-left: 26px;
        margin-top: 8px;
        p {
          &:first-child {
            width: 62px;
            margin-right: 16px;
          }
        }
      }
    }
    .btn-section {
      margin-top: 18px;
      .btn-danger {
        background: var(--color-Special);
        border: 0;
        border-radius: 20px;
        min-width: 97px;
        padding: 8px;
        font-weight: 500;
        line-height: 19px;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}
