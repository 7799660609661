#orderScreen {
  &.background {
    background: var(--color-neutral0);
    .not-ready {
      p {
        font-weight: bold;
        font-size: 18px;
        line-height: 26px;
        &:first-child {
          margin-top: 1rem;
        }
      }
    }
    .ready {
      padding: 20px 20px 0 20px;
      h1 {
        text-align: center;
        font-size: 24px;
        &:last-child {
          margin-bottom: 20px;
        }
        &.ready-text {
          color: var(--color-positive);
          font-size: 26px;
        }
      }
    }
    .content {
      margin: 20px;
    }
    .address {
      margin: 20px;
      p {
        margin: 0;
        font-weight: 600;
        &:first-child {
          font-weight: normal;
        }
        &.marg-btm {
          margin-bottom: 20px;
        }
      }
      .get-direction {
        margin: 20px 0;
        color: var(--color-Special);
        font-weight: 600;
      }
    }
  }
}
