#pickup {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--color-accent2);
  .header {
    border: 1px solid var(--border-color);
    background: var(--color-neutral0);
    img {
      position: absolute;
      right: 16px;
      top: 13px;
    }
    a {
      position: absolute;
      left: 16px;
      top: 13px;
      svg {
        font-size: 22px;
        line-height: 37px;
        color: var(--color-neutral900);
      }
    }
    .heading {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: var(--color-neutral800);
      text-transform: uppercase;
      padding: 15px 0;
      text-align: center;
    }
  }
  .restroName {
    padding: 16px 16px 33px 16px;
    background: var(--color-neutral0);
    p {
      font-size: 30px;
      font-weight: bold;
      line-height: 35px;
      color: var(--color-neutral900);
      margin: 0;
      &.readyBy {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        svg {
          color: var(--color-Primary);
          padding-left: 4px;
          font-size: 20px;
          vertical-align: middle;
        }
      }
    }
  }
  .nav {
    justify-content: space-around;
    border: 0;
    background: var(--color-neutral0);
    &.nav-tabs {
      .nav-link {
        background: var(--color-neutral200);
        border-radius: 4px 4px 0px 0px;
        font-size: 12px;
        text-transform: uppercase;
        color: var(--color-neutral900) 000;
        padding-top: 11px;
        border-bottom: 0;
        &.active {
          background: var(--color-accent1);
          border-radius: 4px 4px 0px 0px;
          font-weight: bold;
          font-size: 12px;
          text-transform: uppercase;
          color: var(--color-neutral0);
          padding-top: 11px;
        }
      }
      &.nav-item {
        &.show {
          .nav-link {
            background: var(--color-accent1);
            border-radius: 4px 4px 0px 0px;
            font-weight: bold;
            font-size: 12px;
            text-transform: uppercase;
            color: var(--color-neutral0);
            padding-top: 11px;
          }
        }
      }
    }
  }
  .tab-content {
    border-top: 8px solid var(--color-accent1);
    p {
      background: var(--color-accent1);
      height: 64px;
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      display: flex;
      align-items: center;
      color: var(--color-neutral0);
      border-bottom: 1px solid var(--color-accent2);
      margin-bottom: 0;
      img {
        margin: 0 16px;
      }
    }
  }

  .alert {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    margin: 0;
    color: var(--color-neutral0);
    svg {
      color: var(--color-Special);
      margin-right: 10px;
    }
  }
}
