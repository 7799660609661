.divider {
  height: 12px;
  background: var(--color-neutral200);
}
#dineIn {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // .header {
  //   border: 1px solid var(--border-color);
  //   a {
  //     position: absolute;
  //     left: 16px;
  //     top: 13px;
  //     svg {
  //       font-size: 22px;
  //       line-height: 37px;
  //       color: var(--color-neutral900);
  //     }
  //   }
  //   .heading {
  //     font-weight: 500;
  //     font-size: 16px;
  //     line-height: 19px;
  //     color: var(--color-neutral800);
  //     text-transform: uppercase;
  //     padding: 15px 0;
  //     text-align: center;
  //   }
  // }
  .restroName {
    font-size: 30px;
    font-weight: bold;
    line-height: 35px;
    color: var(--color-neutral900);
    margin: 0;
    padding: 16px 16px 32px 16px;
    border-bottom: 1px solid var(--border-color);
  }
  .reservationDetails {
    padding: 16px;
    p {
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      color: var(--color-neutral900);
    }
  }
  form {
    label {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 8px;
    }
    option:first-child {
      display: none;
    }
    .required {
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      color: var(--color-neutral900) 000;
      margin-top: 8px;
    }
  }
  .footer {
    margin-top: auto;
    width: 100%;
    .btn {
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      color: var(--color-neutral0);
      height: 72px;
      width: 100%;
    }
  }
}
