#sideNav {
  width: 300px;
  padding: 20px;
  h2 {
    color: var(--color-neutral0);
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 28px;
    width: 220px;
  }
  h6 {
    color: var(--color-neutral0);
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    width: 220px;
  }
  .list-group {
    margin-top: 102px;
    .list-group-item {
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      color: var(--color-Special);
      padding: 13px 0;
      border: 0;
      background: transparent;
      cursor: pointer;
      img {
        margin-right: 16px;
      }
    }
  }
}
