#footer {
  height: 86px;
  background-color: var(--color-accent2);
  font-size: 12px;
  line-height: 14px;
  color: var(--color-neutral0);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
}
