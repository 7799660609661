#verifyOrder {
  background-color: white;
  margin: 40px;
  .orderVerifyText {
    font-size: 16px;
    font-weight: 700;
    color: var(--color-Special);
    text-align: center;
    padding-bottom: 25px;
  }
  .linkText {
    font-size: 16px;
    font-weight: 700;
    color: var(--color-Special);
    text-align: center;
    padding: 25px 0px 25px 0px;
  }

  padding: 20px;
  .verifyOrderSubmitbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
  }
}
