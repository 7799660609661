#customize {
  .header-section {
    padding: 24px 16px 40px 16px;
    position: relative;
    background: var(--color-neutral0);
    .increment {
      position: absolute;
      right: 16px;
      top: -32px;
      padding: 11px;
      background: var(--color-neutral0);
      border-radius: 60px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      width: 111px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      &.no-img {
        top: 24px;
      }
      p {
        background: var(--color-Special);
        border-radius: 16px;
        width: 31px;
        height: 31px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-neutral0);
        font-size: 18px;
      }
    }
    h4 {
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
      color: var(--color-neutral900) 000;
      margin: 0;
    }
    h6 {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: var(--color-neutral900) 000;
      margin-bottom: 15px;
    }
    p {
      font-size: 16px;
      line-height: 19px;
      color: var(--color-neutral900) 000;
      margin-bottom: 0;
    }
  }
  .label-section {
    background: var(--color-neutral200);
    padding: 16px;
    p {
      margin: 0;
      display: flex;
      align-items: center;

      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: var(--color-neutral900) 000;
      &.small-text {
        font-size: 12px;
        line-height: 14px;
        color: var(--color-neutral900) 000;
        margin-top: 8px;
      }
      span {
        border: 1px solid var(--color-Special);
        margin-left: 10px;
        padding: 3px 8px;
        border-radius: 9px;

        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: var(--color-Special);
      }
    }
  }
  .form-group {
    padding: 16px;
    padding-bottom: 0;
    margin-bottom: 40px;
    &.last-child {
      margin-bottom: 88px;
    }
  }
  .btn {
    width: 100%;
    height: 48px;
    border-radius: 0;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
  .d-flex {
    p {
      margin-left: auto;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
    }
  }
  .modifier-desc{
    font-size: .9rem;
    margin-left: 20px;
  }
}
